/** @format */

import {
  DashboardOutlined,
  PauseCircleOutlined,
  OrderedListOutlined,
  ReadOutlined,
  BookOutlined,
  InsertRowRightOutlined,
  HeatMapOutlined,
  ContactsOutlined,
  YoutubeOutlined,
  UsergroupAddOutlined,
  UploadOutlined,
  CalculatorOutlined,
  SlidersOutlined,
  NotificationOutlined,
  VideoCameraFilled,
  TeamOutlined,
  DingtalkOutlined,
  WeiboOutlined,
  MailOutlined,
} from "@ant-design/icons";

//  key's which are in use => 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,19,20,21,22,24,25
export const menu = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: "Dashboard",
    route: "/",
    role: [1, 2],
  },
  {
    key: "2",
    icon: <PauseCircleOutlined />,
    label: "City",
    route: "/city",
    role: [1, 2, 5],
  },
  {
    key: "3",
    icon: <HeatMapOutlined />,
    label: "Menu-Location",
    route: "/menu-location",
    role: [1, 2],
  },
  {
    key: "5",
    icon: <OrderedListOutlined />,
    label: "Menu",
    route: "/menu",
    role: [1, 2],
  },
  {
    key: "4",
    icon: <InsertRowRightOutlined />,
    label: "Pages",
    route: "/pages",
    role: [1, 2],
  },
  {
    key: "6",
    icon: <OrderedListOutlined />,
    label: "Footer",
    route: "/footer",
    role: [1, 2],
  },
  // {
  //   key: "7",
  //   icon: <FormOutlined />,
  //   label: "Enquiry Form",
  //   route: "/enquiry-form",
  //   role: [1],
  // },
  {
    key: "8",
    icon: <BookOutlined />,
    label: "Blogs",
    route: "/blogs",
    role: [1, 2, 3],
  },
  {
    key: "23",
    icon: <BookOutlined />,
    label: "Blogs-comments",
    route: "/blogs-comments",
    role: [1, 2, 3],
  },
  {
    key: "9",
    icon: <ReadOutlined />,
    label: "News",
    route: "/news",
    role: [1, 2],
  },
  {
    key: "10",
    icon: <ContactsOutlined />,
    label: "Contact Us",
    route: "/contact-us",
    role: [1, 2],
  },
  {
    key: "11",
    icon: <ReadOutlined />,
    label: "CBSE Syllabus",
    route: "/cbse-syllabus",
    role: [1, 2],
  },
  {
    key: "16",
    icon: <ReadOutlined />,
    label: "NCERT Solutions",
    route: "/ncert-solutions",
    role: [1, 2],
  },
  {
    key: "26",
    icon: <CalculatorOutlined />,
    label: "Calculators",
    route: "/calculators",
    role: [1, 2],
  },
  {
    key: "27",
    icon: <CalculatorOutlined />,
    label: "Converters",
    route: "/converters",
    role: [1, 2],
  },
  // {
  //   key: "25",
  //   icon: <ReadOutlined />,
  //   label: "Learning",
  //   route: "/learning",
  //   role: [1, 2],
  // },
  {
    key: "17",
    icon: <ReadOutlined />,
    label: "Learning Concepts",
    route: "/learning-concepts",
    role: [1, 2],
  },
  // {
  //   key: "18",
  //   icon: <ReadOutlined />,
  //   label: "Learning Beyond",
  //   route: "/learning-beyond",
  //   role: [1, 2],
  // },
  {
    key: "39",
    icon: <TeamOutlined />,
    label: "Parent Resources",
    route: "/parent-resources",
    role: [1, 2],
  },
  {
    key: "19",
    icon: <ReadOutlined />,
    label: "Sample Paper",
    route: "/sample-papers",
    role: [1, 2],
  },
  {
    key: "20",
    icon: <ReadOutlined />,
    label: "Poems",
    route: "/poems",
    role: [1, 2, 3],
  },
  {
    key: "21",
    icon: <WeiboOutlined />,
    label: "Story",
    route: "/story",
    role: [1, 2, 3],
  },
  {
    key: "22",
    icon: <DingtalkOutlined />,
    label: "Events",
    route: "/event",
    role: [1, 2],
  },
  {
    key: "30",
    icon: <SlidersOutlined />,
    label: "Formulas",
    route: "/formulas",
    role: [1, 2, 3],
  },
  {
    key: "31",
    icon: <VideoCameraFilled />,
    label: "Fiction Frolic",
    route: "/video",
    role: [1, 2],
  },
  {
    key: "12",
    icon: <ReadOutlined />,
    label: "Job Post",
    route: "/job-Post",
    role: [1, 2],
  },
  {
    key: "13",
    icon: <MailOutlined />,
    label: "Application Recieved",
    route: "/application-recieved",
    role: [1, 2],
  },
  {
    key: "24",
    icon: <UploadOutlined />,
    label: "Upload File",
    route: "/upload-file",
    role: [1, 2],
  },
  {
    key: "35",
    icon: <NotificationOutlined />,
    label: "Campaign",
    route: "/campaign",
    role: [1, 2, 5],
  },
  {
    key: "38",
    icon: <TeamOutlined />,
    label: "Public Speaking",
    route: "/public-speaking",
    role: [1, 2],
  },
  {
    key: "48",
    icon: <TeamOutlined />,
    label: "Football Challenge",
    route: "/football-challenge",
    role: [1, 2],
  },
  {
    key: "14",
    icon: <YoutubeOutlined />,
    label: "Newsletter",
    route: "/newsletter",
    role: [1, 2],
  },
  {
    key: "15",
    icon: <UsergroupAddOutlined />,
    label: "Users",
    route: "/users",
    role: [1],
  },
];
